import { useState } from 'react';
import { useSalespersons } from './useSalespersons';

const useOrderCollapseRow = (order, onChange) => {
  const { salespersons } = useSalespersons();

  const [loading, setLoading] = useState(false);
  const [rowValue, setRowValue] = useState({
    ...order,
    companyName: order.company.companyName,
    regDate: order.regDate?.slice(0, 10),
    date: order.date?.slice(0, 10),
    live_date: order.live_date?.slice(0, 10),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRowValue((prevRowValue) => ({
      ...prevRowValue,
      [name]: value || null,
    }));
  };

  const saveChanges = async ({ data }) => {
    setLoading(true);
    const body = data ? { ...data } : { ...rowValue };
    try {
      await onChange(body);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = async (event) => {
    const { name, value } = event.target || event;
    const data = { ...rowValue, [name]: value || null };
    setRowValue(data);
    await saveChanges({ data });
  };

  return {
    handleSelectChange,
    handleInputChange,
    saveChanges,
    salespersons,
    loading,
    setLoading,
    rowValue,
    setRowValue,
  };
};

export default useOrderCollapseRow;

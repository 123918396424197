import { TableRow } from '@mui/material';
import CompanyTableCell from './CompanyTableCell';

const CompanySitesTableRow = ({ site, onClick }) => (
  <TableRow onClick={() => onClick(site.id)} sx={{ cursor: 'pointer' }}>
    <CompanyTableCell>{site.domain}</CompanyTableCell>
    <CompanyTableCell sx={{ textAlign: 'end' }}>{site.googleRank}</CompanyTableCell>
    <CompanyTableCell sx={{ textAlign: 'end' }}>{site.totalLeads || 0}</CompanyTableCell>
    <CompanyTableCell sx={{ textAlign: 'end' }}>{site.lastMonthLeads || 0}</CompanyTableCell>
    <CompanyTableCell sx={{ textAlign: 'end' }}>{site.google_analytics_30 || 0}</CompanyTableCell>
  </TableRow>
);

export default CompanySitesTableRow;

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';

const CalendarPopup = ({ open, onClose, onDateSelect, defaultValue, title }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => setSelectedDate(defaultValue ? new Date(defaultValue) : null), [defaultValue]);

  const handlerClose = () => {
    setSelectedDate(null);
    onClose();
  };

  const handleSave = () => {
    onDateSelect(open, selectedDate);
    handlerClose();
  };

  return (
    <Dialog open={!!open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DatePicker
          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          disablePast
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handlerClose}>Cancel</Button>
        <Button onClick={() => setSelectedDate(null)}>Clear Date</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalendarPopup;

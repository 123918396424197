import { Chip, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { Visibility } from '@mui/icons-material';

import { formatDateForTable } from '../../utils/dateFormatter';
import { getSalespersonFullName } from '../../utils/getSalespersonFullName';

const InvoicesTableBody = ({ data, salespersons, role, openInvoice }) => (
  <TableBody>
    {data &&
      data.map((row) => (
        <TableRow hover key={row.id}>
          {(role === 'admin' || role === 'salesperson') && (
            <TableCell component="th" scope="row">
              {row.company_name}
            </TableCell>
          )}
          <TableCell>
            <Chip size="small" mr={1} mb={1} label="Förfallen obetald" color="error" />
          </TableCell>
          <TableCell align="right">{`${row.amount} SEK` || ''} </TableCell>
          <TableCell>{formatDateForTable(row.invoice_date)}</TableCell>
          <TableCell>{formatDateForTable(row.due_date)}</TableCell>
          <TableCell align="right">
            {getSalespersonFullName(salespersons, row.salesperson_id)}
          </TableCell>
          <TableCell align="right">
            <IconButton onClick={() => openInvoice(row.id)} aria-label="View Invoice">
              <Visibility />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
  </TableBody>
);

export default InvoicesTableBody;

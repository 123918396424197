import { Box, Collapse, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/system';

const FullWidthTableCell = styled(TableCell)({
  width: '100%',
  display: 'table-cell',
  paddingBottom: 0,
  paddingTop: 0,
});

const CollapseTableRow = ({ order, open, children }) => (
  <TableRow style={{ background: 'white' }}>
    <FullWidthTableCell colSpan={1000}>
      <Collapse in={open === order.id} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>{children}</Box>
      </Collapse>
    </FullWidthTableCell>
  </TableRow>
);

export default CollapseTableRow;

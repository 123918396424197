import { useEffect, useState } from 'react';
import { getAllSitesCompany } from '../API/companyApi';
import { updateFullOrder } from '../API/orderApi';
import { isUser } from '../utils/jwt';

const useCompanySiteTable = ({ companyName, withOrder }) => {
  const [open, setOpen] = useState(null);
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userInfo = isUser();

  const handelClick = (siteId) => {
    if (siteId === open) {
      setOpen(null);
    } else {
      setOpen(siteId);
    }
  };

  const saveOrder = async (data) => {
    try {
      const body = data && { ...data };
      body.company = body.companyName;
      delete body.companyName;
      const updatedOrders = sites.map((site) => {
        if (site.latestOrderRegDate.id === body.id) {
          return { ...site, latestOrderRegDate: { ...body } };
        }
        return site;
      });
      setSites(updatedOrders);
      await updateFullOrder({ ...body, user_email: userInfo.email });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getSitesList = async () => {
      const res = await getAllSitesCompany(encodeURIComponent(companyName), withOrder);
      setSites(res);
      setIsLoading(false);
    };
    getSitesList();
  }, []);
  return { isLoading, sites, handelClick, open, saveOrder };
};

export default useCompanySiteTable;

import React from 'react';
import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getSalespersonFullName } from '../../utils/getSalespersonFullName';
import { statusOptions } from '../../constants/orderStatus';

import { LEADS_EXPECTATION_NAMES } from '../../constants/leadsExpectationNames';
import { contentManagerOptions } from '../../constants/contentManager';
import useOrderCollapseRow from '../../hooks/useOrderGrid';
import TextAreaWithSignatureButton from '../OptimizationSiteTableComponents.js/TextAreaWithSignatureButton';
import useAuth from '../../hooks/useAuth';

const renderTextField = (
  name,
  label,
  value,
  handleChange,
  saveChanges,
  loading,
  readOnly = false,
) => (
  <Grid item xs={6}>
    <TextField
      fullWidth
      variant="standard"
      label={label}
      name={name}
      value={value || ''}
      onChange={handleChange}
      sx={{ marginBottom: 1 }}
      onBlur={saveChanges}
      InputProps={{
        readOnly: readOnly || loading,
      }}
    />
  </Grid>
);

const renderSelectField = (name, label, value, handleChange, options, readOnly = false) => (
  <Grid item xs={6}>
    <InputLabel style={{ transform: 'scale(0.75)' }}>{label}</InputLabel>
    <Select
      sx={{
        width: '100%',
        '& .MuiInputBase-input': {
          padding: '8px',
        },
      }}
      name={name}
      value={value || ''}
      onChange={handleChange}
      readOnly={readOnly}>
      <MenuItem value="" />
      {options.map(([optionLabel, optionValue]) => (
        <MenuItem key={optionValue} value={optionValue}>
          {optionLabel}
        </MenuItem>
      ))}
    </Select>
  </Grid>
);

const renderDataPicker = (name, label, value, handleChange, readOnly = false) => (
  <Grid item xs={6}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <InputLabel style={{ transform: 'scale(0.75)' }}>{label}</InputLabel>
      <DatePicker
        sx={{
          width: '100%',
          '& .MuiInputBase-input': {
            padding: '8px',
          },
        }}
        value={value ? dayjs(value) : null}
        onChange={(e) => handleChange({ name, value: e?.format('YYYY-MM-DD') || null })}
        textField={(params) => <TextField {...params} size="small" />}
        readOnly={readOnly}
      />
    </LocalizationProvider>
  </Grid>
);

const OrderGrid = ({ order, onChange }) => {
  const { userMe } = useAuth();
  const { handleSelectChange, handleInputChange, saveChanges, salespersons, loading, rowValue } =
    useOrderCollapseRow(order, onChange);
  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: '16px', marginBottom: '16px', maxWidth: '1000px' }}>
      {renderTextField(
        'companyName',
        'Company name',
        rowValue.companyName,
        handleInputChange,
        saveChanges,
        loading,
        true,
      )}
      {renderTextField(
        'url',
        'Landningsida',
        rowValue.url,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderTextField(
        'leadsmail',
        'Email',
        rowValue.leadsmail,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderTextField(
        'leadstelefon',
        'Telefon',
        rowValue.leadstelefon,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderTextField(
        'keyword',
        'Keyword',
        rowValue.keyword,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderTextField(
        'area',
        'Area',
        rowValue.area,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderTextField(
        'product',
        'Product',
        rowValue.product,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderTextField(
        'start_date_deviation',
        'Start date deviation',
        rowValue.start_date_deviation,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderTextField(
        'city',
        'City',
        rowValue.city,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderSelectField(
        'kundkategori',
        'Kund categori',
        rowValue.kundkategori,
        handleSelectChange,
        Object.entries(LEADS_EXPECTATION_NAMES).map(([key, label]) => [label, key]),
        userMe !== 'admin',
      )}
      {renderDataPicker(
        'date',
        'Datum affär',
        rowValue.date,
        handleSelectChange,
        userMe !== 'admin',
      )}
      {renderDataPicker(
        'live_date',
        'Live date',
        rowValue.live_date,
        handleSelectChange,
        userMe !== 'admin',
      )}
      {renderTextField(
        'post_code',
        'Post code',
        rowValue.post_code,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderTextField(
        'adress',
        'Adress',
        rowValue.adress,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderTextField(
        'site_phone',
        'Site phone',
        rowValue.site_phone,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      {renderSelectField(
        'status',
        'Status',
        rowValue.status,
        handleSelectChange,
        Object.entries(statusOptions),
        userMe !== 'admin',
      )}
      {renderSelectField(
        'content_manager',
        'Content manager',
        rowValue.content_manager,
        handleSelectChange,
        Object.entries(contentManagerOptions),
        userMe !== 'admin',
      )}
      {renderSelectField(
        'salesperson_id',
        'Salesperson',
        rowValue.salesperson_id,
        handleSelectChange,
        salespersons.map((sp) => [getSalespersonFullName(salespersons, sp.id), sp.id]),
        userMe !== 'admin',
      )}
      {renderTextField(
        'tracking_id',
        'GA-cod/AB/AW',
        rowValue.tracking_id,
        handleInputChange,
        saveChanges,
        loading,
        userMe !== 'admin',
      )}
      <Grid item xs={12}>
        <TextAreaWithSignatureButton
          onChange={handleInputChange}
          onBlur={saveChanges}
          value={rowValue.notes || ''}
          readOnly={userMe !== 'admin'}
        />
      </Grid>
    </Grid>
  );
};

export default OrderGrid;

import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUsersList } from '../API/usersApi';
import { debounce } from '../utils/debounce';

const useUserStatisticsTable = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const debouncedSearchHandler = useCallback(
    debounce((value) => {
      setDebouncedSearch(value);
    }, 1000),
    [],
  );

  const searchInputHandler = (e) => {
    const value = e.target.value;
    setSearch(value);
    debouncedSearchHandler(value);
    setPage(0);
  };

  const { data: { users = [], count = 0 } = {} } = useQuery(
    ['userStatisticsTable', { search, page, limit }],
    () =>
      getUsersList({
        search: debouncedSearch.toLowerCase(),
        page,
        limit,
        role: 2,
        countActions: 'true',
        getLastLogin: 'true',
        sort: 'count_last_30_days_login',
        searchParams: 'companyName',
      }),
    {
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  return {
    users,
    count,
    page,
    search,
    limit,
    handleChangePage,
    searchInputHandler,
    handleChangeLimit,
  };
};

export default useUserStatisticsTable;

import { TableHead, TableRow } from '@mui/material';
import CompanyTableCell from './CompanyTableCell';

const CompanySiteTableHead = () => (
  <TableHead>
    <TableRow>
      <CompanyTableCell>Sidor</CompanyTableCell>
      <CompanyTableCell sx={{ textAlign: 'end' }}>Ranking</CompanyTableCell>
      <CompanyTableCell sx={{ textAlign: 'end' }}>Total leads</CompanyTableCell>
      <CompanyTableCell sx={{ textAlign: 'end' }}>Leads 30 dagar</CompanyTableCell>
      <CompanyTableCell sx={{ textAlign: 'end' }}>Visitors 30 dagar</CompanyTableCell>
    </TableRow>
  </TableHead>
);

export default CompanySiteTableHead;

import { memo } from 'react';
import { Card, Typography } from '@mui/material';

import ApexChart from '../../ApexChart';
import { COLORS_FOR_PER_MONTH_CHART } from '../../../constants/chartData';
import LeadsPerMonthSelect from './LeadsPerMonthSelect';

const LeadsPerMonthChart = ({ data }) => {
  const options = {
    dataLabels: {
      enabled: false,
    },
    labels: Object.keys(data.intervals),
    colors: COLORS_FOR_PER_MONTH_CHART,
    tooltip: {
      y: {
        formatter: (value, { seriesIndex }) => {
          const label = Object.keys(data.intervals)[seriesIndex];
          const percent = data.percent[label] || 0;
          return `${value} (${percent}%)`;
        },
      },
    },
  };
  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '600px',
        padding: 3,
        '@media (min-width: 960px)': {
          padding: 10,
        },
        position: 'relative',
      }}>
      <Typography
        variant="h3"
        sx={{
          fontSize: {
            xs: '18px',
            md: '24px',
          },
        }}
        gutterBottom>
        Leads Per Month Chart
      </Typography>
      <LeadsPerMonthSelect />
      <ApexChart
        data={Object.values(data.intervals).map((i) => i.length)}
        type="donut"
        options={options}
        height="auto"
        width="auto"
      />
    </Card>
  );
};

export default memo(LeadsPerMonthChart);

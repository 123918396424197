import axios from '../utils/axios';

export const putOrder = async (id, notes, optimizationStartedAt, status) => {
  let query = `?id=${id}`;
  if (status) {
    query += `&status=${status}`;
  }
  if (notes !== null || notes !== undefined) {
    query += `&notes=${notes}`;
  }
  if (optimizationStartedAt) {
    query += `&optimizationStartedAt=${optimizationStartedAt}`;
  }
  try {
    const response = await axios.put(`/api/my-pages/order${query}`);
    return response;
  } catch (error) {
    return console.log(error);
  }
};

export const updateFullOrder = async (body) => {
  try {
    const response = await axios.put('/api/my-pages/order/update', body);
    return response;
  } catch (err) {
    return console.log(err);
  }
};

import { useQuery } from '@tanstack/react-query';

import { Divider as MuiDivider, Grid, Typography as MuiTypography } from '@mui/material';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import UserStatisticsChart from '../UserStatisticsChart';
import { getAdminDashboard } from '../../API/usersApi';
import Loader from '../Loader';
import UserStatisticsTable from '../UserStatisticsTable';

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const UserStatisticsPage = () => {
  const { data: stats } = useQuery({
    queryKey: ['adminDashboard'],
    queryFn: getAdminDashboard,
    keepPreviousData: true,
    staleTime: 60000,
    cacheTime: 60000,
  });
  if (!stats) {
    return <Loader />;
  }

  return (
    <>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item sx={{ margin: '0 0 20px' }}>
          <Typography
            sx={{
              fontWeight: 500,
              '@media (max-width: 600px)': {
                fontSize: '30px',
              },
            }}
            variant="h1"
            gutterBottom>
            {stats.companyName || 'Dashboard'}
          </Typography>
          <Typography variant="h3" sx={{ fontStyle: 'italic', fontWeight: '500' }}>
            Välkommen tillbaka!
          </Typography>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <UserStatisticsChart />
        </Grid>
        <Grid item xs={12} lg={8}>
          <UserStatisticsTable />
        </Grid>
      </Grid>
    </>
  );
};

export default UserStatisticsPage;

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Divider as MuiDivider, Grid, Typography as MuiTypography } from '@mui/material';

import styled from '@emotion/styled';
import { spacing } from '@mui/system';

import Stats from '../../pages/dashboards/Default/Stats';
import { getAdminDashboard } from '../../API/usersApi';
import Loader from '../Loader';
import AverageLeadsChart from './AverageLeadsChart';
import LeadChart from './LeadsChart';
import useAuth from '../../hooks/useAuth';
import ActiveClientChart from '../Charts/ActiveClientChart';
import AverageRatingChart from './AverageRatingChart';

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const AdminDashboardPage = () => {
  const navigate = useNavigate();
  const { userMe } = useAuth();

  const { data: stats } = useQuery({
    queryKey: ['adminDashboard'],
    queryFn: getAdminDashboard,
    keepPreviousData: true,
    staleTime: 60000,
    cacheTime: 60000,
  });

  const handleClick = (value) => {
    navigate(`/${userMe}/sites?isAvailable=${value}`);
  };

  if (!stats) {
    return <Loader />;
  }
  return (
    <>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item sx={{ margin: '0 0 20px ' }}>
          <Typography
            sx={{
              fontWeight: 500,
              '@media (max-width: 600px)': {
                fontSize: '30px',
              },
            }}
            variant="h1"
            gutterBottom>
            {stats.companyName || 'Dashboard'}
          </Typography>
          <Typography variant="h3" sx={{ fontStyle: 'italic', fontWeight: '500' }}>
            Välkommen tillbaka!
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={6} lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 60 }}>Leads per kund </div>}
            amount={stats.averageLeadsPerClientLast30Days}
            chip="30 days"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 55 }}>Snitt ranking</div>}
            amount={stats.averageGoogleRank}
            chip="Annual"
          />
        </Grid>
        <Grid
          sx={{ cursor: 'pointer' }}
          onClick={() => handleClick(1)}
          item
          xs={12}
          sm={6}
          md={6}
          lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 55 }}>Lediga sidor</div>}
            amount={stats.amountOfAvailableSites}
            chip="Annual"
          />
        </Grid>
        <Grid
          sx={{ cursor: 'pointer' }}
          onClick={() => handleClick(0)}
          item
          xs={12}
          sm={6}
          md={6}
          lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 55 }}>Sidor med kunder</div>}
            amount={stats.amountOfNotAvailableSites}
            chip="Annual"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2.4}>
          <Stats
            title={<div style={{ paddingRight: 55 }}>Antal Kunder</div>}
            amount={stats.usersCount}
            chip="Annual"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AverageLeadsChart leadsCount={stats.leadsCounts} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LeadChart leadsInDay={stats.leadsInDay} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ActiveClientChart leadsCount={stats.countActiveCompany} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AverageRatingChart chartData={stats.averageGoogleRankPerYear} />
        </Grid>
      </Grid>
    </>
  );
};
export default AdminDashboardPage;

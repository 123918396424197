import { Box, Button, Collapse } from '@mui/material';
import { useState } from 'react';

const CollapseWithButton = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const onChange = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box>
      <Button onClick={onChange} variant="text">
        {isOpen ? 'Hide' : 'View'} all sites
      </Button>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default CollapseWithButton;

import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Line } from 'react-chartjs-2';
import { useQuery } from '@tanstack/react-query';
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';
import { spacing } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { getUsersList } from '../API/usersApi';
import { getUserStatistics } from '../API/userStatisticsApi';
import { getLastSevenDaysRange, getLastThirtyDaysRange } from '../utils/timeJs';
import { debounce } from '../utils/debounce';

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

const compareDates = (a, b) => new Date(a.date_trunc) - new Date(b.date_trunc);

const UserStatisticsChart = ({ theme }) => {
  const [series, setSeries] = useState(null);
  const [period, setPeriod] = useState('month');
  const [currentUser, setCurrentUser] = useState({ companyName: 'All company', id: 'All company' });
  const [search, setSearch] = useState(null);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchLoader, setSearchLoader] = useState(false);

  const debouncedSearchHandler = useCallback(
    debounce((value) => {
      setDebouncedSearch(value);
    }, 1000),
    [],
  );

  const handleSearchChange = (value) => {
    setSearchLoader(true);
    setSearch(value);
    debouncedSearchHandler(value);
  };

  const { data: searchList = [] } = useQuery(
    ['userStatisticsChart', debouncedSearch],
    async () => {
      const response = await getUsersList({ search: debouncedSearch, page: 0, limit: 50, role: 2 });
      if (!response || !response.users) {
        return [];
      }
      const filtered = response.users.filter(
        (el) => el.companyName !== '' && el.companyName !== null,
      );
      filtered.unshift({ companyName: 'All company', id: 'All company' });
      setSearchLoader(false);
      return filtered;
    },
    {
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  useEffect(() => {
    const getStats = async () => {
      if (!currentUser) {
        return;
      }

      try {
        let statisticData;
        if (period === 'week') {
          statisticData = getLastSevenDaysRange();
        }
        if (period === 'month') {
          statisticData = getLastThirtyDaysRange();
        }

        const param = {
          from: statisticData.from,
          to: statisticData.to,
        };

        if (currentUser.id !== 'All company') {
          param.userId = currentUser.id;
        }
        const statistic = await getUserStatistics(param);
        const sortedStatistic = statistic.sort(compareDates);

        const interval = [];
        const intervalValue = [];
        sortedStatistic.forEach((record) => {
          const shortDate = record.date_trunc.split('T')[0];
          if (interval.includes(shortDate)) {
            const lastIntervalValueIndex = intervalValue.length - 1;
            intervalValue[lastIntervalValueIndex] += record.count;
          } else {
            interval.push(shortDate);
            intervalValue.push(record.count);
          }
        });

        setSeries({ allDay: [...interval], allValue: [...intervalValue] });
      } catch (err) {
        console.error(err);
      }
    };
    getStats();
  }, [currentUser, period]);

  const data = {
    labels: series?.allDay,
    datasets: [
      {
        borderColor: theme.palette.secondary.main,
        tension: 0.4,
        data: series?.allValue,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.0)',
        },
      },

      y: {
        grid: {
          color: 'rgba(0,0,0,0.0375)',
          fontColor: '#fff',
        },
        ticks: {
          callback: (value) => (Number.isInteger(Number(value)) ? value : ''),
        },
        title: {
          display: true,
          text: 'Usage, times',
        },
      },
    },
  };

  const clearInput = () => {
    setCurrentUser(null);
    handleSearchChange('');
    setSeries(null);
  };

  if (!searchList.length && !search) {
    return <h2>You don`t have users</h2>;
  }
  return (
    <Card>
      <CardHeader
        action={
          <div style={{ position: 'relative' }}>
            <CloseIcon
              onClick={clearInput}
              sx={{
                position: 'absolute',
                right: '40px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                zIndex: 1,
                width: 20,
                height: 20,
              }}
            />
            <Autocomplete
              value={currentUser}
              disableClearable
              isOptionEqualToValue={() => true}
              getOptionLabel={(option) => option.companyName}
              options={searchLoader ? [] : searchList}
              sx={{ width: 300, margin: '12px' }}
              size="small"
              onChange={(event, newValue) => {
                setCurrentUser({
                  ...newValue,
                });
                handleSearchChange(newValue.companyName);
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.companyName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  onChange={(event) => handleSearchChange(event.target.value)}
                  {...params}
                  label="Current company"
                />
              )}
            />
          </div>
        }
        title={
          <>
            <Button onClick={() => setPeriod('week')}>Week</Button>
            <Button onClick={() => setPeriod('month')}>Month</Button>
          </>
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <ChartWrapper style={{ height: '300px' }}>
          {series ? <Line data={data} options={options} /> : <div>No company selected</div>}
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};
export default withTheme(UserStatisticsChart);

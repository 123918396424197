import { Fragment } from 'react';
import { TableBody, Table } from '@mui/material';
import Loader from './Loader';
import CompanySiteTableHead from './CompanySiteTableHead';
import CompanySitesTableRow from './CompanySiteTableRow';
import useCompanySiteTable from '../hooks/useCompanySiteTable';
import CollapseTableRow from './CollapsesTableRow';
import OrderGrid from './Order/OrderGrid';

const CompanySitesTable = ({ companyName, withOrder }) => {
  const { isLoading, sites, open, handelClick, saveOrder } = useCompanySiteTable({
    companyName,
    withOrder,
  });

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Table style={{ border: '1px solid #e0e0e0', margin: '15px 0' }}>
      <CompanySiteTableHead />
      <TableBody>
        {sites?.length > 0 &&
          sites.map((site) => (
            <Fragment key={site.id}>
              <CompanySitesTableRow onClick={handelClick} key={site.id} site={site} />
              <CollapseTableRow order={site} open={open}>
                {site.latestOrderRegDate ? (
                  <OrderGrid
                    order={{ ...site.latestOrderRegDate, company: { companyName } }}
                    onChange={saveOrder}
                  />
                ) : (
                  'Order with this domain not found'
                )}
              </CollapseTableRow>
            </Fragment>
          ))}
      </TableBody>
    </Table>
  );
};

export default CompanySitesTable;
